<template>
  <cs-page-user class="views-user-order">
    <cs-panel-default
      title="订单列表"
      class="d-flex flex-column"
      icon="icon-dingdanliebiao"
    >
      <cs-table
        header-cell-class-name="cs-table-header-cell"
        :resizable="false"
        :stripe="true"
        size="medium"
        align="center"
        ref="CsTable"
        border="none"
        :load="loadData"
      >
        <vxe-table-column
          field="createDate"
          title="下单时间"
        ></vxe-table-column>

        <vxe-table-column
          field="orderNumber"
          title="订单编号"
        ></vxe-table-column>
        <vxe-table-column
          field="companyName"
          title="企业名称"
        ></vxe-table-column>
        <vxe-table-column field="receivableAmount" title="应付金额">
          <template v-slot="{ row }">
            <cs-money :value="row.receivableAmount" />
          </template>
        </vxe-table-column>
        <vxe-table-column field="orderStatus" title="状态">
          <template v-slot="{ row }">
            {{ stateOrderStatus.name(row.orderStatus) }}
          </template>
        </vxe-table-column>
        <vxe-table-column field="payType" title="支付方式">
          <template v-slot="{ row }">
            {{ statePayType.name(row.payType) | defaultText }}
          </template>
        </vxe-table-column>
        <vxe-table-column title="操作" fixed="right" width="200">
          <template v-slot="{ row }">
            <a-button size="small" type="link" @click="detialClick(row)"
              >详情</a-button
            >
            <a-button
              size="small"
              type="link"
              @click="payClick(row)"
              v-if="row.orderStatus === 1"
              >去支付</a-button
            >
            <a-button
              v-if="row.isEvaluate === 1 && row.orderStatus === 2"
              size="small"
              type="link"
              @click="evaluateClick(row)"
              >评价</a-button
            >
          </template>
        </vxe-table-column>
      </cs-table>
    </cs-panel-default>
  </cs-page-user>
</template>

<script>
import { stateOrderStatus, statePayType } from "@/config/state/index.js";
import UserEvaluate from "@/components/user/UserEvaluate";

export default {
  data() {
    return {
      stateOrderStatus,
      statePayType,
    };
  },
  methods: {
    async loadData({ page, size }) {
      // 订单列表
      const res =
        await this.api.service.fpc_oapi_order_selectOrderPageByLoginUser(
          {
            pageNum: page,
            pageSize: size,
          },
          {}
        );
      await this.api.toast(res, 0);
      const { content, totalSize } = res.data;
      return {
        data: content,
        total: totalSize,
      };
    },
    payClick({ id }) {
      this.$router.push(`/product/payOrder?id=${id}`);
    },
    detialClick({ id }) {
      this.$router.push(`/user/orderDetails?id=${id}`);
    },
    evaluateClick({ id }) {
      this.api
        .component(UserEvaluate, {
          data: { id },
        })
        .then(() => {
          this.$refs.CsTable.refresh();
        });
    },
  },
};
</script>

<style lang="less" scoped>
.views-user-order {
  /deep/ .cs-panel-content {
    padding: 20px;
    height: calc(100vh - 230px);
    min-height: 660px;
  }
}
</style>
