var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cs-page-user',{staticClass:"views-user-order"},[_c('cs-panel-default',{staticClass:"d-flex flex-column",attrs:{"title":"订单列表","icon":"icon-dingdanliebiao"}},[_c('cs-table',{ref:"CsTable",attrs:{"header-cell-class-name":"cs-table-header-cell","resizable":false,"stripe":true,"size":"medium","align":"center","border":"none","load":_vm.loadData}},[_c('vxe-table-column',{attrs:{"field":"createDate","title":"下单时间"}}),_c('vxe-table-column',{attrs:{"field":"orderNumber","title":"订单编号"}}),_c('vxe-table-column',{attrs:{"field":"companyName","title":"企业名称"}}),_c('vxe-table-column',{attrs:{"field":"receivableAmount","title":"应付金额"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('cs-money',{attrs:{"value":row.receivableAmount}})]}}])}),_c('vxe-table-column',{attrs:{"field":"orderStatus","title":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.stateOrderStatus.name(row.orderStatus))+" ")]}}])}),_c('vxe-table-column',{attrs:{"field":"payType","title":"支付方式"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("defaultText")(_vm.statePayType.name(row.payType)))+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"操作","fixed":"right","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a-button',{attrs:{"size":"small","type":"link"},on:{"click":function($event){return _vm.detialClick(row)}}},[_vm._v("详情")]),(row.orderStatus === 1)?_c('a-button',{attrs:{"size":"small","type":"link"},on:{"click":function($event){return _vm.payClick(row)}}},[_vm._v("去支付")]):_vm._e(),(row.isEvaluate === 1 && row.orderStatus === 2)?_c('a-button',{attrs:{"size":"small","type":"link"},on:{"click":function($event){return _vm.evaluateClick(row)}}},[_vm._v("评价")]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }